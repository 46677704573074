import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { useConsole } from '../hooks/useConsole';
import SEO from '../components/organisms/seo';
import HeroCTA from '../components/modules/cta/heroCta';
import ArticleCTA from '../components/modules/cta/articleCta';
import DonateCTA from '../components/modules/cta/donateCTA';
import JournalCTA from '../components/modules/cta/journalCta';
import CopyCTA from '../components/modules/cta/copyCta';
import ImageCTA from '../components/modules/cta/imageCta';

const IndexPage = ({ data }) => {
  useConsole();

  const { content } = data.homepage.nodes[0];

  return (
    <div className="spacing-top-small">
      <SEO />
      {content.map(
        (module, i) =>
          (module.__typename === 'SanityHeroCta' && (
            <HeroCTA
              key={i}
              imageSrc={module.media[0].image?.asset.fluid}
              alt={module.media[0].image?.alt}
              videoSrc={module.media[0]?.videoFile.asset.url}
              overline={module.overline}
              copy={module.copy}
              pageLink={module.link.pageLink}
              ctaText={module.link.ctaText}
            />
          )) ||
          (module.__typename === 'SanityArticleCta' && (
            <ArticleCTA
              key={i}
              imageSrc={module.media[0].image?.asset.fluid}
              alt={module.media[0].image?.alt}
              videoSrc={module.media[0]?.url}
              overline={module.overline}
              headline={module.headline}
              copy={module.copy}
              pageLink={module.link.pageLink}
              ctaText={module.link.ctaText}
            />
          )) ||
          (module.__typename === 'SanityDonateCta' && <DonateCTA key={i} ctaText={module.ctaText} />) ||
          (module.__typename === 'SanityJournalCta' && (
            <JournalCTA
              key={i}
              imageSrc={module.journalImage.image.asset.fluid}
              alt={module.journalImage.image.alt}
              overline={module.overline}
              headline={module.headline}
              tags={module.tags.options}
              copy={module.copy}
              slug={module.slug}
            />
          )) ||
          (module.__typename === 'SanityCopyCta' && (
            <CopyCTA key={i} overline={module.overline} headline={module.headline} pageLink={module.link.pageLink} ctaText={module.link.ctaText} />
          )) ||
          (module.__typename === 'SanityImageCta' && (
            <ImageCTA
              key={i}
              imageSrc={module.image.image.asset.fluid}
              alt={module.image.image.alt}
              overline={module.overline}
              copy={module.copy}
              pageLink={module.link.pageLink}
              ctaText={module.link.ctaText}
            />
          ))
      )}
    </div>
  );
};

export const query = graphql`
  query Homepage {
    homepage: allSanityHomepage {
      nodes {
        content {
          ... on SanityHeroCta {
            media {
              ... on SanityMainImage {
                alt
                image {
                  asset {
                    fluid(maxWidth: 3600) {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
              }
              ... on SanityVideo {
                videoFile {
                  asset {
                    url
                  }
                }
              }
            }
            overline
            copy
            link {
              ctaText
              pageLink
            }
          }
          ... on SanityArticleCta {
            media {
              ... on SanityMainImage {
                alt
                image {
                  asset {
                    fluid(maxWidth: 3600) {
                      ...GatsbySanityImageFluid
                    }
                  }
                }
              }
              ... on SanityVideo {
                videoFile {
                  asset {
                    url
                  }
                }
              }
            }
            overline
            copy
            headline
            link {
              ctaText
              pageLink
            }
          }
          ... on SanityJournalCta {
            journalImage {
              alt
              image {
                asset {
                  fluid(maxWidth: 3600) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
            overline
            headline
            tags {
              options
            }
            copy
            slug
          }
          ... on SanityCopyCta {
            overline
            headline
            link {
              ctaText
              pageLink
            }
          }
          ... on SanityImageCta {
            image {
              alt
              image {
                asset {
                  fluid(maxWidth: 3600) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
            overline
            copy
            link {
              ctaText
              pageLink
            }
          }
          ... on SanityDonateCta {
            ctaText
          }
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  data: PropTypes.object,
};

export default IndexPage;
